import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Helmet from 'react-helmet';
import {getSrc} from 'gatsby-plugin-image'
import {fixedImageFragment} from '@components/Image';

const LOGO_QUERY = graphql`
  query LOGO_QUERY($width: Int = 417, $height: Int, $quality: Int) {
    logoFile: file(relativePath: {eq: "2u-logo.png"}) {
      ...fixedImageFragment
    }
  }
`;

interface JsonLdProps {
  // the schema is impossible to type since all are different
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any;
  images?: string[];
  addPublisher?: boolean;
  addLogo?: boolean;
}
const JsonLd = ({
  schema,
  images = [],
  addPublisher = false,
  addLogo = true,
}: JsonLdProps): JSX.Element => {
  const {logoFile} = useStaticQuery(LOGO_QUERY);

  const logo = getSrc(logoFile)//.childImageSharp.fixed.src;

  const imageAry = [...images, logo];

  // publisher object needs logo included so moved to inside component
  const publisher = {
    publisher: {
      '@type': 'Organization',
      name: '2U, Inc.',
      logo: {
        '@type': 'imageObject',
        url: `${logo}`,
      },
    },
  };

  const jsonSchema = {
    ...schema,
    ...(addLogo && {logo}),
    image: imageAry,
    ...(addPublisher && publisher),
  };

  return (
    <Helmet>
      <meta property="og:image" content={`https://2u.com${imageAry[0]}`} />
      <meta property="twitter:image" content={`https://2u.com${imageAry[0]}`} />
      <script type="application/ld+json">{JSON.stringify(jsonSchema)}</script>
    </Helmet>
  );
};

export default JsonLd;
