import React from 'react';

import HeroSectionStandalone from '@components/Hero';
import {HeroSectionCallout} from '@components/SectionCallout';
import {ThemeProvider, Colors} from '@styles';

export default function HeroSection({heroProps, type}): JSX.Element {
  return (
    <ThemeProvider
      theme={{
        maxWidth: '700px',
        fg: Colors.White,
        bg: Colors.Transparent,
        ctaBg: Colors.Transparent,
        ctaFg: Colors.White,
        ctaHoverBg: Colors.White,
        ctaHoverFg: Colors.Blue,
      }}
    >
      <HeroSectionStandalone heroSectionType={type}>
        <HeroSectionCallout heroProps={[...heroProps]} />
      </HeroSectionStandalone>
    </ThemeProvider>
  );
}
