import React from 'react';

import {useContainerSize} from '@components/layout';
import {styled, Sizes} from '@styles';

import {AmbientVideo} from '@components/Video';
import Image from '@components/Image';

import {ImageBlock, VideoBlock, ResponsiveContainerProps} from './types';

// .    .    copy copy
// left imga copy copy
// left main main brgt

/* 
b a o o
b m m d
c m m d
*/

interface _props {
  imageBlocks: ImageBlock[];
  videoBlocks: VideoBlock[];
  callout: JSX.Element;
}
type _el = JSX.Element | null;
type _imgProps = {img: ImageBlock} & ResponsiveContainerProps;
type _vidProps = {vid: VideoBlock} & ResponsiveContainerProps;

const Img = ({img, ...props}: _imgProps): _el => {
  return (
    <Image
      childImageSharp={img.image.childImageSharp}
      style={{
        // maxWidth: img.image.childImageSharp.fluid.presentationWidth,
        width: '100%', // need this to fit grid containers ~adamt
        height: '100%', // need this to fit grid containers, maybe
        objectFit: 'cover',
      }}
      preservePresentationWidth={false}
      alt={img.caption}
      {...img.image}
    />
  );
};

// we need this fix positioning in scaled videos
const FitAmbientVideo = styled(AmbientVideo).withConfig({
  componentId: 'oigwcFitAmbientVideo'
})`
  object-fit: cover;
`;
const Vid = ({vid, ...props}: _vidProps): _el => {
  if (vid.video && vid.video.url) {
    return <FitAmbientVideo videoSrc={vid.video.url} />;
  }
  return null;
};

const wideStyle = `
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  max-width: ${Sizes.LudicrousWide}px;
  justify-content: center;
  grid-template-areas:
    ". . o o"
    "b a o o"
    "b m m ."
    "c m m ."
    "c m m d";
  grid-template-rows: auto 360px auto auto auto;
  grid-template-columns: 225px 340px auto 225px;
  .organicGridSlotA {
    grid-area: a;
    width: 100%;
    height: 100%;
    place-self: stretch;
  }
  .organicGridSlotB {
    grid-area: b;
    width: 225px;
    height: 270px;
    place-self: end center;
  }
  .organicGridSlotC {
    grid-area: c;
    maxWidth: 150px;
    maxHeight: 150px;
    width: 150px;
    height: 150px;
    place-self: start center;
  }
  .organicGridSlotD {
    grid-area: d;
    width: 225px;
    height: 225px;
    place-self: end start;
  }
  .organicGridMain {
    grid-area: m;
    place-self: start stretch;
    padding-bottom: 30px;
  }
  .organicGridCallout {
    grid-area: o;
    min-width: 20px;
    place-self: end start;
  }
  `;
const narrowStyle = `
display: grid;
grid-column-gap: 20px;
grid-row-gap: 20px;
justify-content: center;
grid-template-areas:
  "o o"
  "b a"
  "m m"
  "c d";
grid-template-rows: auto calc(50vw - 50px) auto auto;
grid-template-columns: 1fr 1fr;
.organicGridSlotA,
.organicGridSlotB,
.organicGridSlotC,
.organicGridSlotD,
.organicGridSlotM,
.organicGridCallout
 {
  width: 100%;
  height: 100%;
  place-self: stretch;
}
.organicGridSlotA { grid-area: a; }
.organicGridSlotB { grid-area: b; }
.organicGridSlotC { grid-area: c; }
.organicGridSlotD { grid-area: d; }
.organicGridMain { grid-area: m; }
.organicGridCallout { grid-area: o; }
`;

const OGrid = styled.div.withConfig({
  componentId: 'oigwcOGrid'
})<ResponsiveContainerProps>`
  ${({wide}) => (wide ? wideStyle : narrowStyle)}
`;

const OrganicImageGridWithCallout = ({
  imageBlocks,
  videoBlocks,
  callout,
}: _props): _el => {
  const sizes = useContainerSize();

  return (
    <OGrid {...sizes}>
      <div className="organicGridSlotA">
        <Img img={imageBlocks[0]} {...sizes} />
      </div>
      <div className="organicGridSlotB">
        <Vid vid={videoBlocks[0]} {...sizes} />
      </div>
      <div className="organicGridSlotC">
        <Img img={imageBlocks[1]} {...sizes} />
      </div>
      <div className="organicGridSlotD">
        <Img img={imageBlocks[2]} {...sizes} />
      </div>
      <div className="organicGridMain">
        {videoBlocks[1] ? (
          <Vid vid={videoBlocks[1]} {...sizes} />
        ) : (
          <Img img={imageBlocks[3]} {...sizes} />
        )}
      </div>
      <div className="organicGridCallout">{callout}</div>
    </OGrid>
  );
};

export default OrganicImageGridWithCallout;
