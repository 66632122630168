import React from 'react';

import {BodySectionCallout} from '@components/SectionCallout';
import Section from './StyledSection';
import {ThemeProvider, Colors, styled} from '@styles';
import UniversityPartnersCalloutGrid from '@components/UniversityPartners/UniversityPartnersCalloutGrid';
import getSingleNormalizedLink from '../../util/getSingleNormalizedLink';
import Image from '@components/Image';
import {TwoColumn, useContainerSize} from '@components/layout';
import CTA from '@components/CTA';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';

const CopyContainer = styled.div.withConfig({
  componentId: 'ourPartnersSectionCopyContainer'
})`
  max-width: 100%;
`;

const StyledTwoColumn = styled(TwoColumn).withConfig({
  componentId: 'outPartnersSectionTwoColumn'
})`
  padding: 4em 2em;
  margin: 0 auto;
  .wide & {
    padding: 10em 0;
  }
`;

const BackgroundContainer = styled.div.withConfig({
  componentId: 'outcomePartnersBackgroundContainer'
})`
  padding: 5em 0 0;
  position: relative;
  overflow: visible;
  .wide & {
    padding: 10em 0 0;
  }
`;

const LargeImage = styled.img.withConfig({
  componentId: 'outcomePartnersLargeImage'
})`
  mix-blend-mode: multiply;
  width: 100%;
  position: absolute;
  margin-top: -7em;
  .wide & {
    margin-top: -10em;
  }
`;

const ContentContainer = styled.div.withConfig({
  componentId: 'outcomePartnersContentContainer'
})`
  
  grid-template-columns: 1fr;
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  .wide & {
    padding: 4em;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    align-items: center;
    position: relative;
    max-width: 80%;
  }
`;

const ImageContainer = styled.div.withConfig({
  componentId: 'outcomePartnersContentContainer'
})`
  
  grid-template-columns: 1fr;
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  .wide & {
    padding: 4em;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    align-items: center;
    position: relative;
    max-width: 80%;
  }
`;

const StyledCTA = styled(CTA).withConfig({
  componentId: 'ourPartnersCTA'
})`
  margin: 1em 0;
`;

export default function OurPartersSection({...ourPartners}): JSX.Element {
  const {headerbodyBlock, pagelinkBlock, filelinkBlock, externallinkBlock, linkBlock, imageBlock} = ourPartners
  const {wide} = useContainerSize();
  const link = getSingleNormalizedLink([
    pagelinkBlock,
    filelinkBlock,
    externallinkBlock,
    linkBlock,
  ]);
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.White,
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        minPadding: 'unset',
        maxPadding: 'unset',
        maxWidth: wide ? '80%' : '100%',
      }}
    >
      <Section aria-label={`Learn about our partners`}>
        <StyledTwoColumn>
          <ContentContainer>
            {headerbodyBlock && (
              <ThemeProvider
                theme={{
                  minPadding: wide ? '0 4em' : 0,
                  maxPadding: '0 0',
                  maxWidth: wide ? '80%' : '100%',
                }}
              >
                <RichTextCallout
                  {...(headerbodyBlock ? headerbodyBlock : headerimagebodyBlock)}
                />
              </ThemeProvider>
            )}
            {link && (
              <ThemeProvider>
                <StyledCTA href={link.url}>{link.text}</StyledCTA>
              </ThemeProvider>
            )}
          </ContentContainer>
          { imageBlock && imageBlock.image && <ImageContainer>
              <Image
                childImageSharp={imageBlock.image.childImageSharp}
                style={{
                  margin: '0',
                  width: '100%',
                  height: '100%',
                }}
                preservePresentationWidth={true}
                alt={imageBlock.caption}
                {...imageBlock.image}
              />
            </ImageContainer>
          }
        </StyledTwoColumn>
      </Section>
    </ThemeProvider>
  );
}
