import {useState, useCallback} from 'react';
import useIntersection from '@hzdg/use-intersection';

/**
 * `useFirstIntersection` is a React hook for components that care
 * whether some element has _ever_ intersected the root (viewport).
 *
 * Accepts an optional `IntersectionObserverInit` config, and
 * returns a tuple containing a `boolean` and a callback ref,
 * where the `boolean` represents whether or not the element
 * passed to the callback ref has _ever_ intersected the viewport.
 */
export default function useFirstIntersection(
  intersectionInit?: IntersectionObserverInit,
): [boolean, (node: HTMLElement | null) => void] {
  const [intersected, setIntersected] = useState(false);
  const handleIntersectionChange = useCallback(
    (intersectionState: IntersectionObserverEntry): void => {
      if (intersectionState.isIntersecting) {
        setIntersected(true);
      }
    },
    [],
  );
  const intersectionRef = useIntersection(
    handleIntersectionChange,
    intersectionInit,
  );
  return [intersected, intersectionRef];
}
