/*
 * Taks an array of link objects and returns a normalized version of the first
 * one that has a url
 **/
import {NormalizedLink, MultipleLinkBlockType} from '../types';

type GenericLink = MultipleLinkBlockType | undefined | null;

export default (
  data: Array<GenericLink>,
): NormalizedLink | null | undefined => {
  const validLink: GenericLink | undefined = data.find(link => {
    switch (link?.blockType) {
      case 'filelink_block':
        return link.caption!='' && link.file !=null? true : false;
      case 'externallink_block':
        return link.caption!='' && link.link !=''? true : false;
      case 'pagelink_block':
        return link.title!='' && link.url !=''? true : false;
      case 'link_block':
        return link.caption!='' && link.link !=''? true : false;
      default:
          return false;
    }
  });

  // console.log(validLink);

  if (validLink == null) return null;

  if ('url' in validLink && 'text' in validLink) return validLink;

  if ('page' in validLink) {
    validLink.blockType = 'pagelink_block';
  }

  switch (validLink.blockType) {
    case 'link_block':
      return {
        blockType: validLink.blockType,
        text: validLink.caption,
        url: validLink.link,
      };
    case 'filelink_block':
      return {
        blockType: validLink.blockType,
        text: validLink.caption,
        url: validLink.file ? validLink.file.url : '',
      };
    case 'externallink_block':
      return {
        blockType: validLink.blockType,
        text: validLink.caption,
        url: validLink.link,
      };
    case 'pagelink_block':
      return {
        blockType: validLink.blockType,
        text: validLink.title,
        url: validLink.page ? validLink.page.url : '',
      };
  }
};
