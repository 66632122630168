import React from 'react';

import CTA from '@components/CTA';
import {Headline, Paragraph} from '@components/typography';
import {styled, Sizes, theme} from '@styles';

export interface BodySectionCalloutProps {
  headerBlock: {
    header: string;
    subheader: string;
  };
  pagelinkBlock: {
    title: string;
    page: {
      url: string;
    };
  };
  alignment: 'center' | 'right' | 'left';
  className?: string;
  margin: string;
}

const CalloutContainer = styled.div.withConfig({
  componentId: 'babySectionCalloutContainer'
})`
  background: ${theme.bg};
  max-width: ${({margin}) => margin || `${Sizes.Wide}px`};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: ${({alignment}: {alignment: string}) =>
    alignment === 'right'
      ? 'flex-end'
      : alignment === 'center'
      ? 'center'
      : 'flex-start'};
`;

const LinkContainer = styled.div.withConfig({
  componentId: 'babySectionLinkContainer'
})`
  display: flex;
  width: auto;
  height: auto;
  margin: 1.5em 0;
`;

export default function BodySectionCallout({
  headerBlock,
  pagelinkBlock,
  alignment = 'left',
  className,
  margin,
}: BodySectionCalloutProps): JSX.Element {
  const {header, subheader} = headerBlock;
  return (
    <CalloutContainer
      alignment={alignment}
      className={className}
      margin={margin}
    >
      <Headline center={alignment === 'center'}>{header}</Headline>
      <Paragraph center={alignment === 'center'}>{subheader}</Paragraph>
      {pagelinkBlock && pagelinkBlock.page && pagelinkBlock.page.url && (
        <LinkContainer>
          <CTA href={pagelinkBlock.page.url}>{pagelinkBlock.title}</CTA>
        </LinkContainer>
      )}
    </CalloutContainer>
  );
}
