import React from 'react';
import Testimonial, {TestimonialWithBackground} from '@components/Testimonial';
import {ThemeProvider, Colors} from '@styles';
import {useContainerSize, ResponsiveContainer} from '@components/layout';

export interface TestimonialProps {
  id: string;
  testimonialBlock: object;
  pagelinkBlock1: object;
  pagelinkBlock2: object;
  withBg: boolean;
}

export default function TestimonialSection({
  testimonialBlock,
  pagelinkBlock1,
  pagelinkBlock2,
  withBg = false,
}: TestimonialProps): JSX.Element {
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        bg: Colors.Transparent,
        ctaFg: Colors.Charcoal,
        quoteFg: withBg ? Colors.Robin : Colors.Blue,
        fg: wide && withBg ? Colors.White : Colors.Charcoal,
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.Blue,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        maxPadding: '4em 0',
        maxWidth: wide ? '90%' : '100%',
      }}
    >
      {withBg ? (
        <TestimonialWithBackground
          author={testimonialBlock.author}
          body={testimonialBlock.body}
          bgImage={testimonialBlock.bgimage}
          ctas={[pagelinkBlock1, pagelinkBlock2]}
        />
      ) : (
        <Testimonial
          {...testimonialBlock}
          ctas={[pagelinkBlock1, pagelinkBlock2]}
        />
      )}
    </ThemeProvider>
  );
}
