import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import {ThemeProvider} from '@styles';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {ResponsiveContainer, FontScale} from '@components/layout';
import JsonLd from '@components/JsonLd';
import {
  LatestSection,
  OurCareersSection,
  TestimonialSection,
  OutcomesSection,
  OurPartnersSection,
  FatStatSection,
  FullWidthCreativeSection,
  HeroSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  body: Block[];
  hero: Block[];
}

export default function Home({data}: {data: Data}): JSX.Element {
  const {cms} = data;
  const {page} = cms;
  const {hero, body, seoTitle, searchDescription, canonicalUrl} = page;
  const {header, pagelink1, pagelink2, video} = getDataFromBlockTypes(hero);
  const externalLinks = hero.filter(item => item.blockType == 'link_block');

  const {
    testimonial,
    ourPartners,
    ourPlatform,
    outcome,
    career,
    mediagrid,
    universityStat,
    studentStat,
    ourpartnerdoubleimage,
  } = getDataFromBlockTypes(body);
  
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <JsonLd
        schema={{
          '@context': 'https://schema.org',
          '@type': 'Corporation',
          name: '2U, Inc.',
          alternateName: '2U',
          url: 'http://2u.com',
          description: `${searchDescription}`,
          sameAs: [
            'https://www.facebook.com/2u',
            'https://twitter.com/2Uinc',
            'https://www.linkedin.com/company/2u',
            'https://en.wikipedia.org/wiki/2U_(company)',
          ],
        }}
      />
      <ThemeProvider theme={{minPadding: '2em'}}>
        <ResponsiveContainer as={FontScale}>
          <HeroSection
            heroProps={[header, pagelink1, pagelink2, video, externalLinks]}
            type={'tall'}
          />
          <ThemeProvider theme={{minPadding: 'unset'}}>
            <FullWidthCreativeSection {...video} />
          </ThemeProvider>
          { ourPlatform && <OneImageWithContentSection {...ourPlatform} /> }
          { ourPartners && <OurPartnersSection {...ourPartners} /> }
          { ourpartnerdoubleimage && <TwoImagesWithContentSection {...ourpartnerdoubleimage} contentLeft={true} swapMobile={true} /> }
          { outcome && (
            <ThemeProvider theme={{minPadding: 'unset'}}>
              <OutcomesSection {...outcome} />
            </ThemeProvider>
          )}
          { universityStat && <FatStatSection {...universityStat} /> }
          { testimonial && <TestimonialSection {...testimonial} /> }
          { studentStat && <FatStatSection {...studentStat} /> }
          <OurCareersSection {...career} {...mediagrid} />
          <LatestSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment HomepageFragment on CMS_HomePage {
    seoTitle
    searchDescription
    canonicalUrl
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
      ...videoCmsFragment
      ...externalLinkBlockFragment
    }
    body {
      __typename
      ...sectionBlockFragment
      ...testimonialBlockFragment
      ...mediaGridFragment
      ...sectionImageBlockFragment
      ...sectionBodyImageFragment
      ...sectionBodyTwoImagesFragment
    }
  }
  query Homepage {
    cms {
      page(url: "/home/") {
        ...HomepageFragment
      }
    }
  }
`;
