import React from 'react';
import {graphql} from 'gatsby';
import omit from 'lodash.omit';

import {TwoImagesOrganicGridWithContent} from '@components/OrganicImageGrid';
import {TwoImageGridProps} from '@components/OrganicImageGrid/TwoImagesOrganicGridWithContent';
import Section from './StyledSection';
import {ThemeProvider, Colors, styled} from '@styles';
import {ResponsiveContainer, useContainerSize} from '@components/layout';
import {ResponsiveContainerProps} from '../OrganicImageGrid/types';
interface SectionProps extends TwoImageGridProps {
  margin: string;
  className?: any;
}

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'twoiwcsContainer'
})`
  margin: 2em 0;

  &.wide {
    margin: ${({margin}: {margin: string}) => (margin ? margin : '4em 0 6em')};
  }
`;

const ContentGrid = styled(props => (
  <ResponsiveContainer
    breakpoints={{wide: 960}}
    {...omit(props, 'wide', 'contentLeft', 'offset', 'noPadding')}
  />
)).withConfig({
  componentId: 'twoiwcsContentGrid'
})<ResponsiveContainerProps>`
  display: grid;
  grid-column-gap: 2em;
  grid-row-gap: 1em;
  grid-template-areas:
    'content'
    'images';
  grid-template-columns: 1fr;

  &.wide {
    padding-left: ${({contentLeft, noPadding}) =>
      contentLeft && !noPadding ? '4em' : 0};

    ${({contentLeft}) =>
      contentLeft
        ? `grid-template-areas: 'content images'; grid-template-columns: 1fr 2fr;`
        : `grid-template-areas: 'images content'; grid-template-columns: 1.5fr 1fr; grid-column-gap: 4em;`};

    ${({offset}) => !offset && `grid-template-columns: 1fr 1fr;`}
  }
`;

export default function TwoImagesWithContentSection({
  className = '',
  offset = true, // move image above title on wide
  noPadding = false,
  contentLeft = true, // default to contentLeft
  swapMobile = false,
  ...props
}: SectionProps): JSX.Element {
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.Transparent,
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        maxWidth: wide ? '80%' : '100%',
      }}
    >
      <Section className={className}>
        <Container margin={props.margin}>
          <ContentGrid
            contentLeft={contentLeft}
            noPadding={noPadding}
            offset={offset}
          >
            <TwoImagesOrganicGridWithContent
              {...props}
              offset={offset}
              contentLeft={contentLeft}
              swapMobile={swapMobile}
            />
          </ContentGrid>
        </Container>
      </Section>
    </ThemeProvider>
  );
}

export const sectionBodyTwoImagesFragment = graphql`
  fragment sectionBodyTwoImagesFragment on CMS_SectionBody2ImagesBlock {
    blockType
    headerbodyBlock {
      headline
      body
    }
    pagelinkBlock {
      blockType
      title
      page {
        url
      }
    }
    linkBlock {
      blockType
      caption
      link
    }
    imageBlock {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
    imageBlock2 {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
  }
`;

export const sectionBodyMediaGrid2Block = graphql`
  fragment sectionBodyMediaGrid2Block on CMS_SectionBodyMediaGrid2Block {
    __typename
    blockType
    headerbodyBlock {
      headline
      body
    }
    pagelinkBlock {
      blockType
      title
      page {
        url
      }
    }
    mediagridBlock {
      ... on CMS_MediaGridBlock {
        __typename
        imageBlocks {
          caption
          attribution
          image {
            ...cmsFluidImageFragment
          }
        }
        videoBlocks {
          video {
            url
          }
          image {
            ...cmsFluidImageFragment
          }
          youtube
          attribution
          caption
        }
      }
    }
  }
`;

export const introBlockFragment = graphql`
  fragment introBlockFragment on CMS_IntroBlock {
    id
    headerBlock {
      blockType
      header
      items
      subheader
    }
    blockType
    pagelinkBlock {
      blockType
      title
      page {
        url
        urlPath
      }
    }
    imageBlock1 {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
    videoBlock2 {
      video {
        url
      }
      image {
        ...cmsFluidImageFragment
      }
      youtube
      attribution
      caption
    }
  }
`;
