export default function getFocalPoint(
  focalPointX: number,
  focalPointY: number,
  originalImageWidth: number,
  originalImageHeight: number,
  containerWidth: number,
  containerHeight: number,
): string {
  /**
   * is it the best default to return?
   */
  if (!(focalPointX && focalPointY)) {
    return `center center`;
  }
  /**
   * desired focal point on X axis from the leftmost edge of the bounding box.
   */
  const bX = 0;
  /**
   * desired focal point on Y axis from the leftmost edge of the bounding box.
   */
  const bY = 0;
  /**
   * desired focal point on X axis from the leftmost edge of the background image
   */
  const cX = Math.floor((focalPointX / originalImageWidth) * 100);
  /**
   * desired focal point on Y axis from the leftmost edge of the background image
   */
  const cY = Math.floor((focalPointY / originalImageHeight) * 100);
  /**
   * zoom factor on X axis (background width ÷ bounding box width)
   */
  const xRatio = Math.floor(originalImageWidth / containerWidth);
  /**
   * zoom factor on Y axis (background width ÷ bounding box width)
   */
  const yRatio = Math.floor(originalImageHeight / containerHeight);
  /**
   * background-position-x
   */
  const xPosition =
    (cX + (50 - bX) / Math.max(1, xRatio) - 50) *
      (xRatio / Math.max(1, xRatio - 1)) +
    50;
  /**
   * background-position-y
   */
  const yPosition =
    (cY + (50 - bY) / Math.max(1, yRatio) - 50) *
      (yRatio / Math.max(1, yRatio - 1)) +
    50;
  const xyPosition = `${xPosition.toFixed(0)}% ${yPosition.toFixed(0)}%`;
  return xyPosition;
}
