import React from 'react';
import useHover from '@hzdg/use-hover';
import {styled, Colors, theme, Fonts} from '@styles';
import {TwoColumn, ResponsiveContainer} from '@components/layout';
import {ForwardLink} from '@components/Link';
import Image from '@components/Image';
import {FluidObject} from 'gatsby-plugin-image';

interface FatStatProps {
  stat:
    | string
    | {
        image: {childImageSharp: {fluid: FluidObject}; caption?: string};
      };
  description: string;
  link?:
    | {
        title: string;
        url: string;
      }
    | {title: string; page: {url: string}}
    | {
        text: string;
        url: string;
      };
}

const Grid = styled(TwoColumn).withConfig({
  componentId: 'fatStatGrid'
})`
  align-items: center;
  padding: 2.5em 0 4.5em;
  max-width: 1100px;
  margin: 0 auto;
  grid-template-areas:
    'stat'
    'description';
  .wide & {
    padding: 10em 0;
    grid-template-areas: 'description stat';
  }
`;

const Description = styled.div.withConfig({
  componentId: 'fatStatDescription'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${34 / 16}em;
  line-height: ${40 / 34};
  letter-spacing: ${-1.09 / 34}em;

  .wide & {
    font-size: ${40 / 16}em;
    line-height: ${49 / 40};
    letter-spacing: ${-1.09 / 40}em;
  }
`;

const Stat = styled.div.withConfig({
  componentId: 'fatStatStat'
}).attrs(() => ({['aria-hidden']: true}))`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${Colors.Blue};
  font-size: ${100 / 16}em;
  letter-spacing: ${-0.54 / 100}em;
  grid-area: stat;

  .wide & {
    font-size: ${260 / 16}em;
    letter-spacing: ${-9.94 / 260}em;
  }
`;

const DescriptionContainer = styled.div.withConfig({
  componentId: 'fatStatDescriptionContainer'
})`
  max-width: 329px;
  grid-area: description;
`;

const LinkContainer = styled.div.withConfig({
  componentId: 'fatStatLinkContainer'
})`
  margin-top: 1em;
`;

const FatStat = ({description, stat, link}: FatStatProps): JSX.Element => {
  const [isHover, hoverProps] = useHover();

  return (
    <Grid>
      <DescriptionContainer>
        <Description>{description}</Description>
        <LinkContainer>
          {link && (
            <ForwardLink
              isHover={isHover}
              filledOnHover={true}
              href={'page' in link ? link.page.url : link.url}
              {...hoverProps}
            >
              {link.title || link.text}
            </ForwardLink>
          )}
        </LinkContainer>
      </DescriptionContainer>
      <Stat>
        {typeof stat === 'string' ? (
          stat
        ) : (
          <Image
            childImageSharp={stat.image.childImageSharp}
            aria-hidden={true}
            alt={stat.caption || ''}
            {...stat.image}
          />
        )}
      </Stat>
    </Grid>
  );
};

export default FatStat;
