import React from 'react';
import loadable from '@loadable/component';
import useFirstIntersection from './useFirstIntersection';

type LazyFactory<T extends React.ComponentType> = () => Promise<{default: T}>;

export type DeferredExoticComponent<
  T extends React.ComponentType
> = React.LazyExoticComponent<T> & {preload: LazyFactory<T>};

export interface DeferOptions {
  /**
   * Defers loading until the wrapper is going to intersect the viewport.
   * Set this to `false` if you want loading behavior like `React.lazy`.
   *
   * Default is `true`.
   */
  defer?: boolean;
  /**
   * A React Node to use when the deferred component is suspended.
   *
   * Defaults to `<div>Loading...</div>`.
   */
  fallback?: JSX.Element;
  /**
   * A React Component or intrinsic element that wraps the deferred component.
   * This element will receive a `ref` that is used to detect intersection
   * with the viewport, so be sure to use a ref forwarding component if
   * not using an intrinsic element.
   *
   * Defaults to `'div'`.
   */
  wrapper?: NonNullable<React.ElementType>;
}

/**
 * `defer` works similarly to `React.lazy`, but with a few differences:
 *
 * - Renders `null` on SSR (not _technically_ SSR compatible,
 *   but won't break the build)
 * - Wraps the deferred component in a `Suspense` boundary, and
 *   wraps _that_ with a provided wrapper, like
 *   `<Wrapper><Suspense><Deferred {..props} /></Suspense></Wrapper>`
 * - Defers loading of the component until it will enter the viewport
 *
 * @example
 *   const DeferredComponent = defer(
 *    // Use a dynamic import, just like `React.lazy`.
 *    () => import(/'path/to/DefaultExportedComponent'),
 *    // All config is optional.
 *    {defer: false, fallback: <Spinner />, wrapper: 'span'},
 *   )
 *
 *   // Use the component as though it were already loaded;
 *   // It will suspend and show `fallback` when it needs to load.
 *   const SomeComponent = () => <DeferredComponent />
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default function defer<T extends React.ComponentType<any>>(
  factory: LazyFactory<T>,
  options?: DeferOptions,
): DeferredExoticComponent<T> {
  const defer = options?.defer ?? true;
  const Wrapper = options?.wrapper ?? 'div';
  const fallback = options?.fallback ?? <div>Loading...</div>;
  const Component = loadable(factory, {fallback, ssr: false});
  const intersectionOptions: IntersectionObserverInit = {
    // Use 100% bottom margin on root to trigger intersection
    // when the target element is <100vh away from being visible.
    rootMargin: '0px 0px 100% 0px',
  };
  const Deferred = function Deferred(
    componentProps: React.ComponentPropsWithRef<T>,
  ): JSX.Element {
    const [intersected, ref] = useFirstIntersection(intersectionOptions);
    return (
      <Wrapper ref={ref}>
        {(!defer || intersected) && <Component {...componentProps} />}
      </Wrapper>
    );
  };
  Deferred.preload = Component.preload;
  return Deferred as DeferredExoticComponent<T>;
}
