import React from 'react';
import {graphql} from 'gatsby';

import Section from './StyledSection';
import getSingleNormalizedLink from '../../util/getSingleNormalizedLink';
import {FatStat} from '@components/Stats';
import {ThemeProvider, Colors} from '@styles';

export default function FatStatSection({...props}): JSX.Element {
  /**
   * this is done for the time being to normalize the data
   * and not mess up the `FatStat` component anywhere else on the
   * site. Once all `FatStat` component use the data from the cms
   * instead of hardcoded data, this can be removed and the
   * props in the `FatStat` component should be updated.
   */
  const {headline: description, pagelinkBlock, filelinkBlock, externallinkBlock} = props;
  const link = getSingleNormalizedLink([
    pagelinkBlock,
    filelinkBlock,
    externallinkBlock,
  ]);
  const stat = props.stats ? props.stats : props.statsImage;

  return (
    <ThemeProvider
      theme={{
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.Blue,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        fg: Colors.Charcoal,
        bg: Colors.White,
        ctaFg: Colors.Charcoal,
        ctaBg: Colors.White,
        maxPadding: '4em',
        minPadding: '0 2em',
        maxWidth: '90%',
      }}
    >
      <Section aria-label={`Learn about our statistics`}>
        <FatStat {...{description, link, stat}} />
      </Section>
    </ThemeProvider>
  );
}

export const sectionImageBlockFragment = graphql`
  fragment sectionImageBlockFragment on CMS_SectionImageBlock {
    blockType
    headline
    stats
    pagelinkBlock {
      ...pagelinkBlockFragment
    }
    filelinkBlock {
      ...fileBlockFragment
    }
    externallinkBlock {
      ...externallinkBlockFragment
    }
    statsImage {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
  }
`;
