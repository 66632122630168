import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import {BodySectionCallout} from '@components/SectionCallout';
import Section from './StyledSection';
import Image from '@components/Image';
import {Colors, styled, theme, ThemeProvider} from '@styles';

const LADY_IMAGE_QUERY = graphql`
  query($quality: Int) {
    lady: file(relativePath: {eq: "homepage-image-right.png"}) {
      ...fluidImageFragment
    }
    bg: file(relativePath: {eq: "2U-Homepage-logo.svg"}) {
      publicURL
    }
  }
`;

const BackgroundContainer = styled.div.withConfig({
  componentId: 'outcomeSectionBackgroundContainer'
})`
  padding: 5em 0 0;
  position: relative;
  overflow: hidden;
  .wide & {
    padding: 10em 0 0;
  }
`;

const LargeImage = styled.img.withConfig({
  componentId: 'outcomeSectionLargeImage'
})`
  mix-blend-mode: multiply;
  width: 100%;
  position: absolute;
`;

const OutcomeBodySectionCallout = styled(BodySectionCallout).withConfig({
  componentId: 'outcomeSectionBodyCallout'
})`
  z-index: 1;
`;

const LadyImage = styled(Image).withConfig({
  componentId: 'outcomesSectionWomanImage'
})`
  z-index: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  position: relative;
  margin: 0;
  top: -45px;
  > img {
    margin: 0;
  }
  .wide & {
    /**
      * I haven't found a better way, so, sorry, future generation.   
    */
    position: absolute !important;
    top: auto;
    > img {
      margin: 10em 0 0;
    }
  }
`;

const Container = styled.div.withConfig({
  componentId: 'outcomeSectionContainer'
})`
  background-color: ${Colors.Elm};
  padding: 4em 0 0;

  .wide & {
    padding: 10em 0 20%;
  }
`;

const ContentContainer = styled.div.withConfig({
  componentId: 'outcomeSectionContentContainer'
})`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  .wide & {
    padding: 4em;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    align-items: center;
    position: relative;
    max-width: 80%;
  }
`;

export default function OutcomesSection({...outcome}): JSX.Element {
  const {lady, bg} = useStaticQuery(LADY_IMAGE_QUERY);
  return (
    <ThemeProvider
      theme={{
        fg: Colors.White,
        bg: Colors.Transparent,
        ctaBg: Colors.Transparent,
        ctaFg: Colors.White,
        ctaHoverBg: Colors.White,
        ctaHoverFg: Colors.Blue,
      }}
    >
      <Section aria-label={`Learn about our outcomes`}>
        <BackgroundContainer>
          <LargeImage src={bg.publicURL} aria-hidden={true} />
          <Container>
            <ContentContainer>
              <OutcomeBodySectionCallout {...outcome} />
              <LadyImage
                childImageSharp={lady.childImageSharp}
                imgStyle={{height: '100%', width: '100%'}}
                {...lady}
              />
            </ContentContainer>
          </Container>
        </BackgroundContainer>
      </Section>
    </ThemeProvider>
  );
}
